import localforage from 'localforage';
import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { Icons } from '@/shared/ui/illustration/illustration';

export type SearchBarHistoryItem = {
  id: string;
  value: string;
};
type SearchBarHistory = {
  title: string;
  items: SearchBarHistoryItem[];
  iconColor: string;
  icon: keyof Icons;
  closeButton?: boolean;
};

class HeaderStore {
  isSearchHidden: boolean = false;
  searchBarHistory: SearchBarHistory[] = [
    {
      title: 'Recently',
      items: [],
      iconColor: '#6941C6',
      icon: 'clock',
      closeButton: true,
    },
    {
      title: 'Popular',
      iconColor: '#FE9920',
      items: [
        { id: 'section-11', value: 'not' },
        { id: 'section-12', value: 'bot' },
        { id: 'section-13', value: 'some got' },
      ],
      icon: 'popular',
    },
  ];
  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'header',
        properties: ['searchBarHistory'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    ).then(r => {
      //console.log('persist',r);
    });
  }

  get getItemsSize() {
    const items = this.searchBarHistory.flatMap(
      section => section.items,
    ).length;

    return items;
  }

  setHistoryItem(group: 'recently' | 'popular', item: SearchBarHistoryItem) {
    const index = group === 'recently' ? 0 : 1;

    if (
      this.searchBarHistory[index].items.filter(
        searchItem => searchItem?.value === item.value,
      )?.length ||
      !item?.value.trim()
    ) {
      // this.searchBarHistory[index].items = this.searchBarHistory[index].items.filter((searchItem)=> searchItem?.value !== item.value);
    } else {
      this.searchBarHistory[index].items = [
        item,
        ...toJS(this.searchBarHistory[index].items),
      ].slice(0, Number(process.env.NEXT_PUBLIC_MAX_SEARCH_BAR_RESULTS));
    }
  }

  removeHistoryItem(group: 'recently' | 'popular', id: string) {
    const index = group === 'recently' ? 0 : 1;
    this.searchBarHistory[index].items = this.searchBarHistory[
      index
    ].items.filter(searchItem => searchItem?.id !== id);
  }

  setIsSearchHidden(value: boolean | null) {
    this.isSearchHidden = value ?? !this.isSearchHidden;
  }
}

export const headerStore = new HeaderStore();
