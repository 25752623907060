import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

class AdvertisingStore {
  showHeaderAdvBlock: boolean = true;
  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'app',
        properties: ['showHeaderAdvBlock'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    ).then(r => {
      //console.log('persist',r);
    });
  }

  toggleShowHeaderAdvBlock() {
    this.showHeaderAdvBlock = !this.showHeaderAdvBlock;
  }
  get isHydrated() {
    return isHydrated(this);
  }
}

export const advStore = new AdvertisingStore();
