import { useEffect, useRef } from 'react';

import { useStore } from '@/store/context';

import { SessionProviderProps } from './types';

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const authStore = useStore().authorization;
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan) {
      authStore.getUserInfo();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);
  return <>{children}</>;
};
