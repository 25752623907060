import { makeAutoObservable } from 'mobx';

class SidebarStore {
  isSidebarOpened: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleSidebar() {
    this.isSidebarOpened = !this.isSidebarOpened;
  }

  setIsOpened(value: boolean | null) {
    this.isSidebarOpened = value ?? !this.isSidebarOpened;
  }
}

export const sidebarStore = new SidebarStore();
