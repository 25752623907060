'use client';
import { makeAutoObservable } from 'mobx';

import { advStore } from '@/store/adveritising';
import { appStore } from '@/store/app';
import { authorizationStore } from '@/store/authorization';
import { cartStore } from '@/store/cart';
import { favoriteStore } from '@/store/favorites';
import { headerStore } from '@/store/header';
import { itemsWithdrawalStore } from '@/store/items-withdrawal';
import { notificationsStore } from '@/store/notifications';
import { shopStore } from '@/store/shop';
import { sidebarStore } from '@/store/sidebar';

class RootStore {
  app = appStore;
  advertising = advStore;
  withdrawal = itemsWithdrawalStore;
  cart = cartStore;
  shop = shopStore;
  favorite = favoriteStore;
  notifications = notificationsStore;
  sidebar = sidebarStore;
  authorization = authorizationStore;
  header = headerStore;
  constructor() {
    makeAutoObservable(this);
  }
}

export type { RootStore };
export const store = new RootStore();
