import { ToastVariants } from '@entities/toast/ui/toast.types';
import { makeAutoObservable } from 'mobx';

export type BaseNotification = {
  id: string;
  title: string;
  subtitle?: string;
  variant: ToastVariants;
  delay: number;
};

class NotificationsStore {
  toasts = new Map<string | number, BaseNotification>();

  constructor() {
    makeAutoObservable(this);
  }

  addNotification(notification: BaseNotification) {
    if (!this.toasts.has(notification.id)) {
      this.toasts.set(notification.id, {
        ...notification,
      });
    }
  }

  removeNotification(id: string | number) {
    this.toasts.delete(id);
  }
}

export const notificationsStore = new NotificationsStore();
