import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class FavoriteStore {
  favoriteIds: (string | number)[] = [];
  filters: string[] = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'cart',
        properties: ['favoriteIds'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    );
  }

  toggleFavorite(favoriteId: string | number) {
    if (this.favoriteIds.includes(favoriteId)) {
      this.favoriteIds = this.favoriteIds.filter(
        currentId => currentId !== favoriteId,
      );
    } else {
      this.favoriteIds = [favoriteId, ...this.favoriteIds];
    }
  }
  setFilter(filter: string) {
    if (this.filters.includes(filter)) {
      this.filters = this.filters.filter(
        currentFilter => currentFilter !== filter,
      );
    } else {
      this.filters = [filter, ...this.filters];
    }
  }

  resetFilters() {
    this.filters = [];
  }
}

export const favoriteStore = new FavoriteStore();
