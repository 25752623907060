import { useQueryString } from '@hooks/client';
import { usePathname } from 'next/navigation';
import { ReactNode, useEffect } from 'react';

import { useStore } from '@/store/context';

export const NavHistoryProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const { urlSearchParams } = useQueryString();
  const navHistory = useStore().app;

  useEffect(() => {
    if (window && window?.document) {
      navHistory.pushNavigationHistory(pathname, urlSearchParams.toString());
      window.document?.body.scrollTo(0, 0);
    }
  }, [pathname, urlSearchParams]);

  /* useEffect(() => {
    console.log(JSON.stringify(navHistory.navigationHistory, null, 4));
  }, [navHistory.navigationHistory.length]);*/

  return children;
};
