import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/client/pb-front/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager", webpackExports: ["WithHeaderLayout"] */ "/home/app/client/pb-front/src/app/[locale]/layout/mobile/with-header-layout.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/app/[locale]/swiper.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardCategoryEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-category/ui/card-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardItemOfferEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-item-offer/ui/card-item-offer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardItemEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-item/ui/card-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardLiveFeedEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-live-feed/ui/card-live-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPriceItemPageEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-price-item-page/ui/card-price-item-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardShoppingItemEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-shopping-item/ui/card-shopping-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthorizationModal"] */ "/home/app/client/pb-front/src/modals/authorization/ui/authorization.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/home/app/client/pb-front/src/shared/ui/accordion/index.ts");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/avatar/avatar.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/badge/badge.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ModalDesktop"] */ "/home/app/client/pb-front/src/shared/ui/desktop/modal-desktop/modal-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IllustrationModal"] */ "/home/app/client/pb-front/src/shared/ui/illustration-modal/illustration-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemImageByVariant"] */ "/home/app/client/pb-front/src/shared/ui/item/item-image-by-variant/item-image-by-variant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/home/app/client/pb-front/src/shared/ui/label/label.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/home/app/client/pb-front/src/shared/ui/logo/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdditionalInfoMobile"] */ "/home/app/client/pb-front/src/shared/ui/mobile/additional-info/additional-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BottomSheetUi"] */ "/home/app/client/pb-front/src/shared/ui/mobile/bottom-sheet/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TagTimer"] */ "/home/app/client/pb-front/src/shared/ui/tag-timer/tag-timer.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/typography/typography.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/button/button.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/circle-timer/circle-timer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/description/description.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/divider/divider.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/egg-counter/egg-counter.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/illustration/illustration.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/item-rarity-card/item-rarity-card.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/item-rarity-category-card/item-rarity-category-card.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/item-rarity-item-page/item-rarity-item-page.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/modal-content/modal-content.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/modal-wrapper/modal-wrapper.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/progress-indicator/progress-indicator.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/skeleton/skeleton.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/tag-category/tag-category.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/tag-info/tag-info.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/tag-quantity/tag-quantity.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/tag-rarity/tag-rarity.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/entities/desktop/cards/card-item-block/ui/card-item-block.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AdvertisingWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/adveritising/ui/advertising.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/categories/ui/categories.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ContentWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/content/ui/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/footer/ui/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/header/ui/header.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/inventory-history/ui/history.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/app/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ItemCardWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/item-card/ui/item-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemsWithdrawalWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/items-withdrawal/ui/items-withdrawal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListCardsWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards/ui/list-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveFeedWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/live-feed/ui/live-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OfferWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/offer/offer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PriceCardWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/price-card/price-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileHistoryWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/profile-history/ui/profile-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/settings/ui/settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/sidebar/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/toast/ui/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdvertisingWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/advertising/ui/advertising.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/content/ui/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/footer/ui/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/header/ui/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemsWithdrawalWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/items-withdrawal/ui/items-withdrawal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/navigation/ui/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileHistoryWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/profile-history/ui/profile-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileSettingsWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/settings/ui/settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/home/app/client/pb-front/src/widgets/notifications/ui/notifications.tsx");
